// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2px;
  padding: var(--spacing-md) var(--spacing-md);
  margin-left: var(--spacing-md);
  font-size: var(--font-size-xl);
  color: var(--color-white);
  background: linear-gradient(45deg, var(--color-link-hover), var(--color-border-active));
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
}
.file-upload-button:hover {
  background: linear-gradient(25deg, var(--color-link-hover), var(--color-border-active));
  transform: var(--transform-lift); /* Adds a sliding effect on hover */
}
.file-upload-button:active {
  transform: scale(0.95); /* Makes the button look pressed */
}

.file-input-label {
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
}

.file-input {
  margin-bottom: 1px;
}

.upload-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/FileUploadButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,4CAA4C;EAC5C,8BAA8B;EAC9B,8BAA8B;EAC9B,yBAAyB;EACzB,uFAAuF;EACvF,sCAAsC;EACtC,eAAe;EACf,sCAAsC;AACxC;AACA;EACE,uFAAuF;EACvF,gCAAgC,EAAE,mCAAmC;AACvE;AACA;EACE,sBAAsB,EAAE,kCAAkC;AAC5D;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;AACb","sourcesContent":[".file-upload-button {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin: 2px;\n  padding: var(--spacing-md) var(--spacing-md);\n  margin-left: var(--spacing-md);\n  font-size: var(--font-size-xl);\n  color: var(--color-white);\n  background: linear-gradient(45deg, var(--color-link-hover), var(--color-border-active));\n  border-radius: var(--border-radius-md);\n  cursor: pointer;\n  transition: all var(--transition-fast);\n}\n.file-upload-button:hover {\n  background: linear-gradient(25deg, var(--color-link-hover), var(--color-border-active));\n  transform: var(--transform-lift); /* Adds a sliding effect on hover */\n}\n.file-upload-button:active {\n  transform: scale(0.95); /* Makes the button look pressed */\n}\n\n.file-input-label {\n  margin-bottom: 5px;\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.file-input {\n  margin-bottom: 1px;\n}\n\n.upload-progress {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
