// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-output {
  text-align: left; /* Ensures markdown lists are well aligned */
  font-size: var(--font-size-markdown);
  padding: var(--spacing-lg);
  background-color: var(--color-markdown-bg);
  border-radius: var(--border-radius-lg);
  margin-top: var(--spacing-xl);
  box-shadow: var(--box-shadow-deep);
  transition: background-color var(--transition-fast);
}

.markdown-output:hover {
  background-color: var(--color-markdown-hover-bg); /* Subtle lightening on hover */
}`, "",{"version":3,"sources":["webpack://./src/components/styles/OutputSection.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,4CAA4C;EAC9D,oCAAoC;EACpC,0BAA0B;EAC1B,0CAA0C;EAC1C,sCAAsC;EACtC,6BAA6B;EAC7B,kCAAkC;EAClC,mDAAmD;AACrD;;AAEA;EACE,gDAAgD,EAAE,+BAA+B;AACnF","sourcesContent":[".markdown-output {\n  text-align: left; /* Ensures markdown lists are well aligned */\n  font-size: var(--font-size-markdown);\n  padding: var(--spacing-lg);\n  background-color: var(--color-markdown-bg);\n  border-radius: var(--border-radius-lg);\n  margin-top: var(--spacing-xl);\n  box-shadow: var(--box-shadow-deep);\n  transition: background-color var(--transition-fast);\n}\n\n.markdown-output:hover {\n  background-color: var(--color-markdown-hover-bg); /* Subtle lightening on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
