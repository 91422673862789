// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  font-size: var(--font-size-base);
  border-radius: var(--border-radius-sm);
  border: 2px solid var(--color-border-active);
  outline: none;
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  transition: border-color var(--transition-fast);
}

.dropdown:focus {
  border-color: var(--color-link-hover); /* Similar focus effect to input */
}

body.dark-mode .dropdown {
  background-color: var(--color-input-bg-dark);
  color: var(--color-input-text-dark);
  transition: background-color var(--transition-normal), color var(--transition-normal);
}`, "",{"version":3,"sources":["webpack://./src/components/styles/PersonaSelector.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,0BAA0B;EAC1B,gCAAgC;EAChC,sCAAsC;EACtC,4CAA4C;EAC5C,aAAa;EACb,uCAAuC;EACvC,8BAA8B;EAC9B,+CAA+C;AACjD;;AAEA;EACE,qCAAqC,EAAE,kCAAkC;AAC3E;;AAEA;EACE,4CAA4C;EAC5C,mCAAmC;EACnC,qFAAqF;AACvF","sourcesContent":[".dropdown {\n  margin-top: var(--spacing-md);\n  padding: var(--spacing-md);\n  font-size: var(--font-size-base);\n  border-radius: var(--border-radius-sm);\n  border: 2px solid var(--color-border-active);\n  outline: none;\n  background-color: var(--color-input-bg);\n  color: var(--color-input-text);\n  transition: border-color var(--transition-fast);\n}\n\n.dropdown:focus {\n  border-color: var(--color-link-hover); /* Similar focus effect to input */\n}\n\nbody.dark-mode .dropdown {\n  background-color: var(--color-input-bg-dark);\n  color: var(--color-input-text-dark);\n  transition: background-color var(--transition-normal), color var(--transition-normal);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
